<template>
  <div id="report-plataforma">
    <v-container grid-list-xl fluid>
      <header-title title="Reporte por plataforma"></header-title>
      <v-row>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="initDateFormat"
                label="Fecha inicio"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_inicio"
              no-title
              @input="menu1 = false"
              @change="getCheckDate"
              locale="es"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateFormat"
                label="Fecha fin"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="fecha_inicio == ''"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_fin"
              :min="fecha_inicio"
              no-title
              @input="menu2 = false"
              @change="getReportPlataformas()"
              locale="es"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showStatistic">
            <v-icon>mdi-image-outline</v-icon>Gráfica
          </v-btn>
        </v-col> -->
      </v-row>
      <div v-if="atendidos.length > 0">
        <v-row>
          <v-col cols="12" md="8">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Plataformas</th>
                    <th class="text-center">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in atendidos" :key="index">
                    <td>{{ item.plataforma }}</td>
                    <td class="text-center">{{ item.cantidad }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="grey lighten-4" v-if="atendidos.length > 0">
                    <th>Total</th>
                    <th class="text-center">
                      {{ total }}
                    </th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="atendidos.length > 0">
          <v-col cols="12">
            <highcharts class="chart" :options="chartPlataforma"></highcharts>
          </v-col>
        </v-row>
        <v-row v-if="role == 2 || role == 3">
          <v-col cols="12" v-if="headers.length > 0">
            <h4 class="grey--text py-3">Trámites realizados por plataforma</h4>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      v-for="(item, index) in headers"
                      :key="index"
                    >
                      {{ item.plataforma }}
                    </th>
                    <th class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rows, ix) in operador_plataforma" :key="ix">
                    <td
                      v-for="(cols, jx) in rows"
                      :key="jx"
                      :class="
                        jx == 0 ? 'text-left grey lighten-4' : 'text-center'
                      "
                    >
                      {{ cols }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="grey lighten-4">
                    <th
                      class="text-center"
                      v-for="(item, index) in total_cols"
                      :key="index"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <span class="red--text text--lighten-2">{{ message }}</span>
      </div>
    </v-container>
    <v-dialog v-model="mdialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Detalle de atención</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Contribuyente
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ atencion.nombres }} {{ atencion.primer_ape }}
              {{ atencion.segundo_ape }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Teléfono: {{ atencion.fono ? atencion.fono : "-" }} Celular:
              {{ atencion.celular ? atencion.celular : "-" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Hora de inicio y fin
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ atencion.hora_inicio }} - {{ atencion.hora_fin }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Fecha de atención
            </v-list-item-title>
            <v-list-item-subtitle>{{
              atencion.fecha_atencion | formatdate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="teal--text py-0 my-0">
              Observaciones
            </v-list-item-title>
            <span class="grey--text text--darken-1">
              {{ atencion.observacion }}
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">Estado</v-list-item-title>
            <v-list-item-subtitle>
              {{ atencion.estado }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click.native="mdialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "report-plataforma",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      message: "Seleccione fecha de inicio y fin.",
      loading: false,
      menu1: false,
      menu2: false,
      headers: [],
      operators: [],
      total_cols: [],
      operador_plataforma: [],
      atencion: {},
      atendidos: [],
      plataformas: [],
      plataforma_id: "",
      mdialog: false,
      role: "",
      usuario_id: 0,
      fecha_inicio: "",
      fecha_fin: "",
      snack: {
        state: false,
        color: "success",
        mode: "",
        timeout: 2500,
        text: "",
      },
    };
  },
  computed: {
    initDateFormat() {
      return this.fecha_inicio == "" ? "" : this.formatDate(this.fecha_inicio);
    },
    endDateFormat() {
      return this.fecha_fin == "" ? "" : this.formatDate(this.fecha_fin);
    },
    total: function () {
      let sum = 0;
      return this.atendidos.reduce(
        (sum, item) => sum + parseInt(item.cantidad == "" ? 0 : item.cantidad),
        0
      );
    },
  },
  mounted() {
    if (Service.getUser()) {
      this.role = Service.getUser().role;
      if (this.role != 1) {
        if (this.role == 4) {
          this.usuario_id = Service.getUser().uid;
        }
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getCheckDate() {
      if (this.fecha_inicio > this.fecha_fin && this.fecha_fin != "") {
        this.fecha_fin = this.fecha_inicio;
        this.getReportPlataformas();
      }
    },
    getReportPlataformas() {
      this.loadingOperador = true;
      this.atendidos = [];
      this.headers = [];
      this.operators = [];
      this.total_cols = [];
      this.plataformas = [];
      this.operador_plataforma = [];
      axios
        .post(
          Service.getBase() + "reporte/plataforma",
          {
            fecha_inicio: this.fecha_inicio,
            fecha_fin: this.fecha_fin,
            usuario_id: this.usuario_id,
          },
          Service.getHeader()
        )
        .then((response) => {
          this.loadingOperador = false;
          this.atendidos = response.data;
          if (this.atendidos.length > 0) {
            let datag = [];
            for (const key in this.atendidos) {
              datag.push({
                name: this.atendidos[key].plataforma,
                y: this.atendidos[key].cantidad * 1,
              });
            }
            this.chartPlataforma = {
              chart: {
                type: "column", //spline
              },
              title: {
                text: "Trámites Atendidos",
              },
              subtitle: {
                text: "por Plataforma",
              },
              accessibility: {
                announceNewData: {
                  enabled: true,
                },
              },
              xAxis: {
                type: "category",
              },
              yAxis: {
                title: {
                  text: "Cantidad",
                },
              },
              credits: {
                enabled: false,
              },
              legend: {
                enabled: false,
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                  },
                },
              },
              tooltip: {
                pointFormat:
                  '<span style="color:{point.color}">Cantidad</span>: <b>{point.y}</b> total<br/>',
              },
              series: [
                {
                  name: "Plataformas",
                  colorByPoint: true,
                  data: datag,
                },
              ],
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      {
                        text: "Imprimir",
                        textKey: "printChart",
                        onclick: function () {
                          this.print();
                        },
                      },
                      {
                        separator: true,
                      },
                      {
                        text: "Descargar PNG",
                        textKey: "downloadPNG",
                        onclick: function () {
                          this.exportChart();
                        },
                      },
                      {
                        text: "Descargar JPG",
                        textKey: "downloadJPEG",
                        onclick: function () {
                          this.exportChart({
                            type: "image/jpeg",
                          });
                        },
                      },
                    ],
                  },
                },
              },
            };
            if (this.role == 2 || this.role == 3) {
              this.getReportOperadorPlataforma();
            }
          } else {
            this.message = "No existen registros.";
          }
        })
        .catch((error) => {
          this.loadingOperador = false;
          this.message = "No se pudo obtener registros.";
          console.error("Error al cargar registros", error);
        });
    },

    getReportOperadorPlataforma() {
      axios
        .post(
          Service.getBase() + "reporte/operador/plataforma",
          { fecha_inicio: this.fecha_inicio, fecha_fin: this.fecha_fin },
          Service.getHeader()
        )
        .then((response) => {
          let datos = response.data;
          this.headers = [
            { plataforma_id: 0, plataforma: "OPERADOR / PLATAFORMA" },
          ];
          let datag = [];
          for (let index = 0; index < datos.length; index++) {
            let pointer = this.headers.findIndex(
              (item) => item.plataforma_id === datos[index].plataforma_id
            );
            if (pointer == -1) {
              this.headers[index + 1] = {
                plataforma_id: datos[index].plataforma_id,
                plataforma: datos[index].nombre_plataforma,
              };
            }
            pointer = this.operators.findIndex(
              (item) => item.usuario_id === datos[index].usuario_id
            );
            if (pointer == -1) {
              this.operators.push({
                usuario_id: datos[index].usuario_id,
                operador: datos[index].nombre_operador,
              });
            }
          }
          for (const ix in this.operators) {
            let new_row = new Array(this.headers.length).fill(0),
              sum = 0;
            new_row[0] = this.operators[ix].operador;
            for (const jx in datos) {
              if (this.operators[ix].usuario_id == datos[jx].usuario_id) {
                let pointer = this.headers.findIndex(
                  (item) => item.plataforma_id === datos[jx].plataforma_id
                );
                new_row[pointer] = parseInt(datos[jx].cantidad);
                sum += parseInt(datos[jx].cantidad);
              }
            }
            new_row.push(sum);
            this.operador_plataforma.push(new_row);
          }
          this.total_cols = new Array(this.operador_plataforma.length).fill(0);
          this.total_cols = this.operador_plataforma.reduce(function (arr, a) {
            a.forEach(function (b, index) {
              arr[index] = (arr[index] || 0) + b;
            });
            return arr;
          }, []);
          this.total_cols[0] = "Total";
        })
        .catch((error) => {
          this.loadingOperador = false;
          console.error("Error al cargar registros", error);
        });
    },

    showDetail(id) {
      this.atencion = id;
      this.mdialog = true;
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
